<template>
  <section :ref="`elementList${filterSection}`">
    <li
      v-for="(option, index) in filterSection.values.filter(
        opt => opt.selected
      )"
      :key="index"
      class="clebex-item-section-item no-bottom-border light no-separator"
    >
      <div
        class="clebex-item-content-wrapper cursor-pointer"
        @click="
          $emit('selectFilter', {
            section: filterSection,
            value: option
          });
          $emit('validate');
        "
      >
        <div class="clebex-section-input">
          <label class="clebex-section-input-label cursor-pointer"
            >{{ option.name
            }}<span
              v-if="['resources', 'levels'].includes(filterSection.slug)"
              class="parents"
              >{{ displayParentNames(option.all_parents) }}</span
            >
          </label>
        </div>
        <span v-if="option.selected" class="follow-up-icons">
          <span class="follow-up-icon-item">
            <div class="svg-icon">
              <svg width="12" height="12" style="width: 12px; height: 12px;">
                <use xlink:href="#cx-app1-checkmark"></use>
              </svg>
            </div>
          </span>
        </span>
      </div>
    </li>
    <li
      v-for="(option, index) in elements.filter(opt => !opt.selected)"
      :key="index"
      class="clebex-item-section-item no-bottom-border light no-separator"
    >
      <div
        class="clebex-item-content-wrapper cursor-pointer"
        @click="
          $emit('selectFilter', {
            section: filterSection,
            value: option
          });
          scrollToView($event, filterSection);
          $emit('validate');
        "
      >
        <div class="clebex-section-input">
          <label class="clebex-section-input-label cursor-pointer"
            >{{ option.name
            }}<span
              v-if="['resources', 'levels'].includes(filterSection.slug)"
              class="parents"
              >{{ displayParentNames(option.all_parents) }}</span
            ></label
          >
        </div>
        <span v-if="option.selected" class="follow-up-icons">
          <span class="follow-up-icon-item">
            <div class="svg-icon">
              <svg width="12" height="12" style="width: 12px; height: 12px;">
                <use xlink:href="#cx-app1-checkmark"></use>
              </svg>
            </div>
          </span>
        </span>
      </div>
    </li>
  </section>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";
import { mapState, mapActions } from "vuex";
import { getLang } from "@/services/http-service";

export default {
  name: "SearchFiltersSectionSelect",
  data() {
    return {
      paginationData: null,
      loading: false,
      topScreenElement: 1,
      elementHeight: null,
      elementListTop: null,
      paginationCountActive: false,
      timeout: null,
      notPaginated: false
    };
  },
  computed: {
    ...mapState("search", ["filters", "presets"]),
    locale() {
      return getLang();
    },
    selectedPreset() {
      if (this.$route.params.id && this.presets && this.presets.length) {
        const preset = this.presets.find(el => el.id == this.$route.params.id);
        if (preset) {
          return preset;
        }
        return null;
      }
      return null;
    },
    selectedPresetId() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return null;
    },
    elements() {
      const query = this.searchQuery;
      if (
        this.filterSection.values &&
        this.filterSection.values.length &&
        (!this.paginationData ||
          (this.paginationData &&
            this.paginationData.last_page === 1 &&
            !this.beSearchQuery))
      ) {
        if (query && query.length > 1) {
          return this.filterSection.values.filter(item =>
            `${item.name}`.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.filterSection.values;
        }
      }
      return this.filterSection.values;
    }
  },
  created() {
    this.getFilterValues(true);
  },
  methods: {
    ...mapActions("search", ["setSelectedFilters"]),
    scrollToView(event, element) {
      if (element.multi || !event.path) {
        return;
      }
      const path = event.path.find(el => el.className === "expanded five");
      if (path) {
        path.scrollTop = 0;
      }
    },
    getFilterValues(reset, searchQuery) {
      this.loading = true;
      let pagination = "";
      if (!this.paginationData || reset) {
        pagination = "page=1";
      } else {
        pagination = `page=${this.paginationData.current_page + 1}`;
      }
      let query = "";
      if (searchQuery) {
        query = `&query=${searchQuery}`;
      }
      if (!this.paginationData || reset) {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
      }
      let prefix = this.filterSection.endpoint.includes("?") ? "&" : "?";
      httpServiceAuth
        .get(`${this.filterSection.endpoint}${prefix}${pagination}${query}`)
        .then(response => {
          this.notPaginated = response.data.meta ? false : true;
          this.$emit("notPaginated", this.notPaginated);
          let updatedFilters = { ...this.filters };
          let section = updatedFilters.filters.find(
            el => el.slug === this.filterSection.slug
          );
          if (!this.paginationData || reset) {
            if (section) {
              section.values = response.data.data;
            }
            this.$store.commit("search/setFilters", updatedFilters);
          } else {
            section.values = [...section.values, ...response.data.data];
            this.$store.commit("search/setFilters", updatedFilters);
          }
          if (!this.notPaginated) {
            this.paginationData = response.data.meta;
          }
          if (
            !this.notPaginated &&
            response.data.meta.current_page < 3 &&
            response.data.meta.last_page &&
            response.data.meta.last_page > response.data.meta.current_page
          ) {
            this.getFilterValues(false);
          } else if (this.selectedPreset) {
            this.setSelectedFilters(this.selectedPreset.id);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    handleScroll() {
      if (this.$refs[`elementList${this.filterSection}`]) {
        this.topScreenElement =
          Math.floor(
            (this.elementListTop -
              this.$refs[
                `elementList${this.filterSection}`
              ].getBoundingClientRect().top) /
              this.elementHeight
          ) + 1;
        this.topScreenElement =
          this.topScreenElement > 1 ? this.topScreenElement : 1;
      }
    },
    displayParentNames(parents) {
      if (parents && parents.length) {
        const filteredParents = parents.filter(item => item.name);
        return `, ${filteredParents
          .map(parent =>
            parent.translations &&
            parent.translations.find(el => el.locale === this.locale)
              ? parent.translations.find(el => el.locale === this.locale)
                  .attributes.name
              : parent.name
          )
          .join(", ")}`;
      }
      return null;
    }
  },
  watch: {
    selectedPresetId: {
      handler() {
        this.getFilterValues(true);
      }
    },
    scroll() {
      if (!this.notPaginated) {
        this.handleScroll();
      }
    },
    mainScroll() {
      if (!this.notPaginated) {
        this.elementListTop = this.$refs[
          `elementList${this.filterSection}`
        ].getBoundingClientRect().top;
      }
    },
    elements() {
      if (!this.notPaginated) {
        this.$nextTick(() => {
          if (!this.elementListTop) {
            this.elementListTop = this.$refs[
              `elementList${this.filterSection}`
            ].getBoundingClientRect().top;
            this.elementHeight =
              this.$refs[`elementList${this.filterSection}`].offsetHeight /
              this.elements.length;
          }
          this.$emit("scroll", {
            topScreenElement: this.topScreenElement,
            paginationCountActive: this.paginationCountActive,
            paginationData: this.paginationData
          });
        });
      }
    },
    topScreenElement() {
      if (!this.notPaginated) {
        if (
          this.paginationData.current_page > 2 &&
          !this.loading &&
          this.paginationData.to - this.topScreenElement < 100 &&
          this.paginationData.current_page < this.paginationData.last_page
        ) {
          this.getFilterValues(false);
        }
        this.paginationCountActive = true;
        this.$emit("scroll", {
          topScreenElement: this.topScreenElement,
          paginationCountActive: this.paginationCountActive,
          paginationData: this.paginationData
        });
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.paginationCountActive = false;
          this.$emit("scroll", {
            topScreenElement: this.topScreenElement,
            paginationCountActive: this.paginationCountActive,
            paginationData: this.paginationData
          });
        }, 3000);
      }
    },
    startBeSearchTrigger() {
      if (
        (this.paginationData && this.paginationData.last_page > 1) ||
        (this.paginationData &&
          this.paginationData.last_page === 1 &&
          this.beSearchQuery) ||
        !this.loading
      ) {
        this.getFilterValues(true, this.searchQuery);
      }
    }
  },
  props: {
    filterSection: {
      type: Object,
      required: true
    },
    scroll: {
      type: Number,
      required: true
    },
    mainScroll: {
      type: Number,
      required: true
    },
    searchQuery: {
      type: String,
      required: true
    },
    startBeSearchTrigger: {
      type: Number,
      required: false,
      default: 0
    },
    beSearchQuery: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

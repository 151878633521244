<template>
  <section class="pagination-element-list">
    <div
      v-if="paginationData && paginationCountActive && !notPaginated"
      class="clebex-pagination-count-wrapper"
    >
      <span class="clebex-pagination-count"
        >{{ topScreenElement }}/{{ paginationData.total }}</span
      >
    </div>
    <li
      class="clebex-item-section-item"
      :class="
        collapse[filterSection.slug] && filterSection.type !== 'switch'
          ? 'collapsed'
          : 'expanded'
      "
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label larger-label">
            {{ displayLabelName(`search.presets.${filterSection.slug}`) }}
            <span v-if="filterSection.multi" class="selected-total"
              >({{ filterSection.values.filter(el => el.selected).length }}/{{
                !filterSection.endpoint || notPaginated
                  ? filterSection.values.length
                  : paginationData && paginationData.total
                  ? paginationData.total
                  : ""
              }})</span
            >
          </label>
        </div>
        <span
          v-if="filterSection.type !== 'switch'"
          class="follow-up-icons cursor-pointer"
        >
          <section
            v-if="
              filterSection.type !== 'number' &&
                (filterSection.values.length > 10 ||
                  beSearchQuery[filterSection.slug]) &&
                search[filterSection.slug] &&
                !collapse[filterSection.slug]
            "
          >
            <search-component
              :ref="`search${filterSection.slug}`"
              :searchQuery="searchQuery[filterSection.slug]"
              @inputChanged="inputChanged($event, filterSection.slug)"
              @lostFocus="lostFocus(filterSection.slug)"
              @enter="startBeSearch(filterSection.slug)"
              @clearSearch="clearSearch(filterSection.slug)"
            ></search-component>
          </section>
          <span
            v-if="
              filterSection.type !== 'number' &&
                !collapse[filterSection.slug] &&
                (filterSection.values.length > 10 ||
                  beSearchQuery[filterSection.slug])
            "
            @click="startSearch(filterSection.slug)"
            class="follow-up-icon-item cursor-pointer"
            ><icon icon="#cx-hea1-search" width="12" height="12"
          /></span>
          <span
            @click="
              collapse[filterSection.slug]
                ? (collapse[filterSection.slug] = false)
                : (collapse[filterSection.slug] = true)
            "
            class="follow-up-icon-item collapsable"
            ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
          /></span>
        </span>
        <div
          v-if="filterSection.type === 'switch'"
          class="checkbox slide"
          :class="{ disabled: filterSection.disabled }"
        >
          <input
            :id="`settings-visitor-management-${filterSection.slug}`"
            type="checkbox"
            v-model="defaultValue"
            @click="$emit('validate')"
          />
          <label
            :for="`settings-visitor-management-${filterSection.slug}`"
          ></label>
        </div>
      </div>
    </li>
    <section
      v-if="
        filterSection.type === 'select' &&
          filterSection.values.length &&
          !filterSection.endpoint
      "
      :class="collapse[filterSection.slug] ? 'collapsed' : 'expanded five'"
    >
      <li
        v-for="(option, index) in filterSection.values.filter(
          opt => opt.selected
        )"
        :key="index"
        class="clebex-item-section-item no-bottom-border light no-separator"
      >
        <div
          class="clebex-item-content-wrapper cursor-pointer"
          @click="
            selectFilter({
              section: filterSection,
              value: option
            });
            $emit('validate');
          "
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer"
              >{{ option.name }}
              <span
                v-if="
                  filterSection.slug === 'date_period' &&
                    option.period === 'SPECIFIC_DATES' &&
                    globalDateFormat
                "
                >({{ showDate(option.datetime_from) }}
                -
                {{ showDate(option.datetime_to) }})</span
              ></label
            >
          </div>
          <span v-if="option.selected" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <div class="svg-icon">
                <svg width="12" height="12" style="width: 12px; height: 12px;">
                  <use xlink:href="#cx-app1-checkmark"></use>
                </svg>
              </div>
            </span>
          </span>
        </div>
      </li>
      <li
        v-for="(option, index) in filterSection.values.filter(
          opt => !opt.selected
        )"
        :key="index"
        class="clebex-item-section-item no-bottom-border light no-separator"
      >
        <div
          v-if="
            !searchQuery[filterSection.slug] ||
              searchQuery[filterSection.slug].length < 2 ||
              (searchQuery[filterSection.slug] &&
                searchQuery[filterSection.slug].length &&
                `${option.name}`
                  .toLowerCase()
                  .includes(searchQuery[filterSection.slug].toLowerCase()))
          "
          class="clebex-item-content-wrapper cursor-pointer"
          @click="
            selectFilter({
              section: filterSection,
              value: option
            });
            scrollToView($event, filterSection);
            $emit('validate');
          "
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer"
              >{{ option.name
              }}<span
                v-if="
                  filterSection.slug === 'date_period' &&
                    option.period === 'SPECIFIC_DATES' &&
                    globalDateFormat
                "
                >({{ showDate(option.datetime_from) }}
                -
                {{ showDate(option.datetime_to) }})</span
              ></label
            >
          </div>
          <span v-if="option.selected" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <div class="svg-icon">
                <svg width="12" height="12" style="width: 12px; height: 12px;">
                  <use xlink:href="#cx-app1-checkmark"></use>
                </svg>
              </div>
            </span>
          </span>
        </div>
      </li>
    </section>
    <section
      v-if="filterSection.type === 'select' && filterSection.endpoint"
      :class="collapse[filterSection.slug] ? 'collapsed' : 'expanded five'"
      class="paginated-endpoint"
    >
      <filter-section-select
        :filterSection="filterSection"
        :scroll="scroll"
        :mainScroll="mainScroll"
        :searchQuery="
          searchQuery[filterSection.slug] ? searchQuery[filterSection.slug] : ''
        "
        :startBeSearchTrigger="startBeSearchTrigger[filterSection.slug]"
        :beSearchQuery="beSearchQuery[filterSection.slug]"
        @validate="$emit('validate')"
        @scroll="setPagination"
        @selectFilter="selectFilter"
        @notPaginated="setNotPaginated"
      ></filter-section-select>
    </section>
    <section
      v-if="filterSection.type === 'number'"
      :class="collapse[filterSection.slug] ? 'collapsed' : 'expanded one'"
    >
      <div class="capacity-filter-input-section">
        <button
          @click="
            selectFilter({
              section: filterSection,
              value: null,
              numberIncrease: false
            });
            $emit('validate');
          "
          class="capacity-filter-btn decrease-capacity"
        >
          -
        </button>
        <div class="capacity-filter-input-wrapper clebex-section-input">
          {{ filterSection.default }}
        </div>
        <button
          @click="
            selectFilter({
              section: filterSection,
              value: null,
              numberIncrease: true
            });
            $emit('validate');
          "
          class="capacity-filter-btn increase-capacity"
        >
          +
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import FilterSectionSelect from "@/components/search/FilterSectionSelect";
import SearchComponent from "@/components/search/SearchComponent";

export default {
  name: "SearchFiltersSection",
  components: { FilterSectionSelect, SearchComponent },
  data() {
    return {
      collapse: {},
      scroll: 1,
      topScreenElement: 1,
      paginationCountActive: false,
      paginationData: null,
      searchQuery: {},
      search: {},
      startBeSearchTrigger: {},
      beSearchQuery: {},
      notPaginated: false
    };
  },
  mounted() {
    const el = document.getElementsByClassName("paginated-endpoint")[0];
    el.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState("search", ["filters", "presets", "presetSection"]),
    ...mapGetters("settings", ["globalDateFormat"]),
    defaultValue: {
      get() {
        return this.filterSection.default;
      },
      set(value) {
        this.selectFilter({
          section: this.filterSection,
          value: value
        });
      }
    }
  },
  methods: {
    ...mapActions("search", ["selectFilter"]),
    showDate(date) {
      return formatDate(date, this.globalDateFormat);
    },
    scrollToView(event, element) {
      if (element.multi || !event.path) {
        return;
      }
      const path = event.path.find(el => el.className === "expanded five");
      if (path) {
        path.scrollTop = 0;
      }
    },
    handleScroll() {
      this.scroll++;
    },
    setPagination({ topScreenElement, paginationCountActive, paginationData }) {
      this.topScreenElement = topScreenElement;
      this.paginationCountActive = paginationCountActive;
      this.paginationData = paginationData;
    },
    startSearch(section) {
      if (!this.search[section]) {
        if (this.beSearchQuery[section]) {
          this.searchQuery[section] = this.beSearchQuery[section];
        }
        this.search[section] = true;
        this.$nextTick(() => {
          this.$refs[`search${section}`].$refs.searchComponent.focus();
        });
      } else {
        this.search[section] = false;
      }
    },
    inputChanged(val, section) {
      this.searchQuery[section] = val;
    },
    startBeSearch(section) {
      if (this.searchQuery[section].length > 2) {
        this.beSearchQuery[section] = this.searchQuery[section];
        this.startBeSearchTrigger[section] = this.startBeSearchTrigger[section]
          ? this.startBeSearchTrigger[section] + 1
          : 1;
      }
    },
    clearSearch(section) {
      this.searchQuery[section] = "";
      if (this.beSearchQuery[section]) {
        this.startBeSearchTrigger[section] = this.startBeSearchTrigger[section]
          ? this.startBeSearchTrigger[section] + 1
          : 1;
        this.beSearchQuery[section] = "";
      }
    },
    lostFocus(section) {
      setTimeout(() => {
        this.search[section] = false;
      }, 100);
    },
    setNotPaginated(notPaginated) {
      this.notPaginated = notPaginated;
    }
  },
  props: {
    filterSection: {
      type: Object,
      required: true
    },
    mainScroll: {
      type: Number,
      required: true
    }
  },
  beforeUnmount() {
    const el = document.getElementsByClassName("paginated-endpoint")[0];
    el.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<template>
  <mobile-screen
    :header="true"
    screen-class="search-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="search-header icon-hea1">
        <template v-slot:left>
          <button @click="closeFilters">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              selectedPreset
                ? "search.presets.edit-preset"
                : "search.presets.create-preset"
            )
          }}
        </div>
        <template v-slot:right>
          <button v-if="validation" @click="save">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label
              class="clebex-section-input-label larger-label"
              for="presetName"
              >{{ displayLabelName("search.presets.preset-name") }}
            </label>
            <input
              v-if="selectedPreset"
              as="input"
              type="text"
              id="presetName"
              v-model="selectedPreset.name"
            />
            <input
              v-else
              as="input"
              type="text"
              id="presetName"
              v-model="presetName"
            />
          </div>
        </div>
      </li>
    </ul>

    <section v-if="filters && filters.filters && filters.filters.length">
      <ul
        v-for="(section, i) in filters.filters.filter(el => !el.hidden)"
        :key="i"
        class="clebex-item-section overflow-hidden"
      >
        <filter-section
          :filterSection="section"
          :mainScroll="scroll"
          @validate="validate()"
        >
        </filter-section>
      </ul>
    </section>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearAll">
              {{ displayLabelName("search.filters.clear-all") }}
            </button>
          </li>
          <li
            v-if="
              filters &&
                filters.filters &&
                filters.filters.length &&
                filters.filters.find(el => el['select-all'])
            "
            class="action"
          >
            <button class="action-btn" @click="selectAll">
              {{ displayLabelName("search.filters.select-all") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import FilterSection from "@/components/search/FilterSection";

export default {
  name: "SearchFilters",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    FilterSection
  },
  data() {
    return {
      presetName: null,
      validationArray: [],
      scroll: 1
    };
  },
  computed: {
    ...mapState("search", ["filters", "presets", "presetSection"]),
    selectedPreset() {
      if (this.$route.params.id && this.presets && this.presets.length) {
        const preset = this.presets.find(el => el.id == this.$route.params.id);
        if (preset) {
          return preset;
        }
        return null;
      }
      return null;
    },
    validation() {
      this.validate();
      if (this.filters && this.validationArray.length) {
        if (this.filters.required && this.filters.required.length) {
          let valid = [];
          this.filters.required.forEach((element, i) => {
            if (Array.isArray(element)) {
              let oneSelected = false;
              element.forEach((el, index) => {
                const section = this.filters.filters.find(
                  sec => sec.slug === el
                );
                if (this.validationArray[i][index][el]) {
                  oneSelected = true;
                  if (section.required && section.required.length) {
                    let reqValid = [];
                    section.required.forEach(e => {
                      this.validationArray[i].forEach(r => {
                        if (r[e] !== undefined) {
                          reqValid.push(r[e]);
                        }
                      });
                    });
                    if (reqValid.includes(false)) {
                      valid.push(false);
                    } else {
                      valid.push(true);
                    }
                  } else {
                    valid.push(true);
                  }
                }
              });
              if (!oneSelected) {
                valid.push(false);
              }
            } else {
              valid.push(this.validationArray[i][element]);
            }
          });
          if (
            valid.includes(false) ||
            (this.selectedPreset ? !this.selectedPreset.name : !this.presetName)
          ) {
            return false;
          }
          return true;
        }
        return true;
      }
      return false;
    }
  },
  created() {
    if (this.presetSection) {
      this.getFilters({
        section: this.presetSection,
        presetId: this.selectedPreset ? this.selectedPreset.id : null
      }).then(() => {
        this.validate();
      });
    } else {
      this.closeFilters();
    }
  },
  mounted() {
    const el = document.getElementsByClassName("screen-content")[
      document.getElementsByClassName("screen-content").length - 1
    ];
    el.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions("search", [
      "getFilters",
      "savePreset",
      "setSelectedFilters",
      "clearAllFilters",
      "updatePreset"
    ]),
    closeFilters() {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    save() {
      if (this.validation) {
        if (this.selectedPreset) {
          this.updatePreset(this.selectedPreset);
        } else {
          this.savePreset(this.presetName).then(() => {
            this.presetName = null;
          });
        }
      }
    },
    clearAll() {
      if (!this.selectedPreset) {
        this.presetName = null;
      }
      this.clearAllFilters();
      this.validate();
    },
    validate() {
      if (
        this.filters &&
        this.filters.required &&
        this.filters.required.length
      ) {
        this.validationArray = [...this.filters.required];
        this.filters.required.forEach((element, i) => {
          if (Array.isArray(element)) {
            let validArr = [...element];
            element.forEach((el, index) => {
              const section = this.filters.filters.find(sec => sec.slug === el);
              if (section.type === "number") {
                validArr[index] = { [el]: section.default ? true : false };
              } else if (section.type === "select") {
                validArr[index] = {
                  [el]: section.values.find(e => e.selected) ? true : false
                };
              }
            });
            this.validationArray[i] = validArr;
          } else {
            const section = this.filters.filters.find(
              sec => sec.slug === element
            );
            if (section.type === "number") {
              this.validationArray[i] = {
                [element]: section.default ? true : false
              };
            } else if (section.type === "select") {
              this.validationArray[i] = {
                [element]: section.values.find(e => e.selected) ? true : false
              };
            }
          }
        });
      }
    },
    handleScroll() {
      this.scroll++;
    }
  },
  watch: {
    selectedPreset: {
      deep: true,
      handler(val, oldVal) {
        if (
          ((val && !oldVal) || (val && oldVal && val.id !== oldVal.id)) &&
          this.filters &&
          this.filters.length &&
          !this.filters.find(el => el.endpoint)
        ) {
          this.setSelectedFilters(val.id);
        }
        if (!val) {
          this.clearAllFilters();
        }
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    }
  },
  beforeUnmount() {
    const el = document.getElementsByClassName("screen-content")[
      document.getElementsByClassName("screen-content").length - 1
    ];
    el.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
